var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "quota__details" }, [
    _c("div", { staticClass: "quota__main mod-child" }, [
      _c("div", { staticClass: "quota__info" }, [
        _vm._v(" Поставщик: "),
        _c("b", [_vm._v(_vm._s(_vm.getSupplierTitle))]),
      ]),
      _c("div", { staticClass: "quota__info" }, [
        _vm._v(" Тип авто: "),
        _c("b", [_vm._v(_vm._s(_vm.getQuotaTypeAuto))]),
      ]),
      !_vm.isArchive
        ? _c("div", { staticClass: "quota__info" }, [
            _vm._v(" Линия: "),
            _c("b", [_vm._v(_vm._s(_vm.getQuotaLine) + " линия")]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }