<template>
  <div class="quota__details">
    <div class="quota__main mod-child">
      <div class="quota__info">
        Поставщик: <b>{{ getSupplierTitle }}</b>
      </div>
      <div class="quota__info">
        Тип авто: <b>{{ getQuotaTypeAuto }}</b>
      </div>
      <div v-if="!isArchive" class="quota__info">
        Линия: <b>{{ getQuotaLine }} линия</b>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_IS_MOBILE, GET_SUPERVISOR_QUOTAS_LINES } from '@/store/actions'
import { GET_SUPPLIERS_ALL } from '@/views/exporter/store/actions'
import { autoTypeSupervisor } from '@/constants/auto-type'
import { mapGetters } from 'vuex'

export default {
  name: 'QuotaDetail',
  props: {
    quota: {
      type: Object,
      default: () => ({}),
    },
    isArchive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      suppliersList: GET_SUPPLIERS_ALL,
      isMobile: GET_IS_MOBILE,
      linesFromState: GET_SUPERVISOR_QUOTAS_LINES,
    }),
    supervisorLines() {
      return this.linesFromState.slice().reverse()
    },
    getSupplierTitle() {
      let supplier = this.suppliersList.find(
        item => item.id === this.quota.suppliers_ids[0],
      )

      return supplier ? supplier.name : 'Без поставщика'
    },
    getQuotaTypeAuto() {
      if (
        this.quota.truck_types.length === autoTypeSupervisor.length ||
        !this.quota.truck_types.length
      ) {
        return 'Любые'
      }
      let typeNames = []

      this.quota.truck_types.forEach(item => {
        typeNames.push(autoTypeSupervisor.find(name => name.id === item).name)
      })

      return typeNames.join(', ')
    },
    getQuotaLine() {
      let index = this.supervisorLines.findIndex(
        item => item.id === this.quota.unload_line_id,
      )

      return index + 1
    },
  },
}
</script>

<style lang="sass" scoped>
.quota__details
  display: flex
  flex-direction: column
  gap: 18px
  margin: 18px 0 18px 27px
  @media (max-width: 1200px)
    margin: 16px 0
    padding: 14px
    background: $color-main-background
    border-radius: 8px
  &-item
    display: flex
    justify-content: space-between
    align-items: center
    @media (max-width: 1200px)
      flex-direction: column

  .mod-child
    flex: 0
    display: block
    color: $grey-font

  .quota__info
    line-height: 1.5

  @media (max-width: 1200px)
    .mod-child
      width: 100%
      margin-right: 0
      margin-bottom: 10px
      padding: 0
      background: transparent
      border: none
</style>
